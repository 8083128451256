import { IApiGateway } from '@/modules/api/api.interface'

import { RecruiterMinimalResponseDto } from '../domain/entities/recruiter.entity'
import { IRecruiterRepository } from '../domain/repositories/recruiter.repository'

export class RecruiterRepository implements IRecruiterRepository {
  constructor(private api: IApiGateway) {}

  async getAllMinimal(): Promise<RecruiterMinimalResponseDto[]> {
    return this.api.get<RecruiterMinimalResponseDto[]>('recruiters', {
      params: {
        output: 'minimal',
      },
    })
  }
}
