import { IApiGateway } from '@/modules/api/api.interface'
import {
  NotiticationRequestDto,
  PaginatedNotificationResponse,
} from '@/modules/notification/domain/entities/notification.entity'
import { INotificationRepository } from '@/modules/notification/domain/repositories/notification.repository'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'
import { PaginationDto } from '@/shared/domain/entities/pagination.entity'

export class NotificationReposity implements INotificationRepository {
  constructor(private api: IApiGateway) {}

  async getAll(pagination: PaginationDto): Promise<PaginatedNotificationResponse> {
    return this.api.get<PaginatedNotificationResponse>('notifications', {
      params: pagination,
    })
  }

  async read(id: string): Promise<IdResponseDto> {
    return this.api.put<IdResponseDto>(`notifications/read/${id}`)
  }

  async unread(id: string): Promise<IdResponseDto> {
    return this.api.put<IdResponseDto>(`notifications/unread/${id}`)
  }

  async readAll(): Promise<boolean> {
    return this.api.put<boolean>('notifications/read-all')
  }

  async create(data: NotiticationRequestDto): Promise<IdResponseDto> {
    return this.api.post<IdResponseDto, NotiticationRequestDto>('notifications', data)
  }
}
