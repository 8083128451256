import { useMutation } from '@tanstack/react-query'

import { useUpdateCache } from '@/hooks/useUpdateCache'
import { ApiGateway } from '@/modules/api/api.gateway'
import { NotificationReposity } from '@/modules/notification/adapter/notification.repository'
import { NotificationUseCase } from '@/modules/notification/application/notification.use-case'
import { NotiticationRequestDto } from '@/modules/notification/domain/entities/notification.entity'

const apiGateway = new ApiGateway()
const notificationRepository = new NotificationReposity(apiGateway)
const notificationUseCase = new NotificationUseCase(notificationRepository)

const useReadNotification = () => {
  const { invalidateQueries } = useUpdateCache()

  return useMutation({
    mutationFn: (id: string) => notificationUseCase.read(id),
    async onSuccess() {
      await invalidateQueries(['notifications'])
    },
  })
}

const useUnreadNotification = () => {
  const { invalidateQueries } = useUpdateCache()

  return useMutation({
    mutationFn: (id: string) => notificationUseCase.unread(id),
    async onSuccess() {
      await invalidateQueries(['notifications'])
    },
  })
}

const useReadAllNotifications = () => {
  const { invalidateQueries } = useUpdateCache()

  return useMutation({
    mutationFn: () => notificationUseCase.readAll(),
    async onSuccess() {
      await invalidateQueries(['notifications'])
    },
  })
}

const useCreateNotification = () => {
  return useMutation({
    mutationFn: (data: NotiticationRequestDto[]) => Promise.all(data.map((item) => notificationUseCase.create(item))),
  })
}

export { useReadNotification, useReadAllNotifications, useCreateNotification, useUnreadNotification }
