import { ComponentProps } from 'react'

import { RecruiterMinimal } from '@/types/recruiter'
import { getFullNameInitial } from '@/utils/string'

import { Avatar, AvatarFallback } from './ui/avatar'
import { AvatarGroup } from './ui/avatar-group'
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'

interface RecruitersAvatarProps<T> {
  recruiters: T[]
  size?: ComponentProps<typeof Avatar>['size']
  max?: number
}

export function RecruitersAvatar<T extends RecruiterMinimal>({ max, recruiters, size }: RecruitersAvatarProps<T>) {
  return (
    <Tooltip>
      <TooltipTrigger>
        <AvatarGroup max={max} size={size}>
          {recruiters.map((recruiter) => (
            <Avatar key={recruiter.id} size={size}>
              <AvatarFallback className={'border border-background'}>{getFullNameInitial(recruiter)}</AvatarFallback>
            </Avatar>
          ))}
        </AvatarGroup>
      </TooltipTrigger>
      <TooltipContent className="flex flex-col gap-y-1 py-3">
        {recruiters.map((recruiter) => (
          <div key={recruiter.id}>
            {recruiter.firstname} {recruiter.lastname}
          </div>
        ))}
      </TooltipContent>
    </Tooltip>
  )
}
