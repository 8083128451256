export const getApplicationMatchingColor = (value: number) => {
  if (value <= 25) return 'red'
  if (value <= 50) return 'yellow'
  if (value <= 75) return 'primary'
  return 'green'
}

export const getQualificationMatchingColor = (value: number) => {
  const percent = value * 100
  return getApplicationMatchingColor(percent)
}

export const getProgressMatchingColor = (value: number) => {
  if (value <= 25) return 'green'
  if (value <= 50) return 'primary'
  if (value <= 75) return 'yellow'
  return 'red'
}
