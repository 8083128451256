import { queryOptions } from '@tanstack/react-query'

import {
  getJobs,
  getJobsBoard,
  getJobsBoardAutomations,
  getJobsDescription,
  getJobsInfo,
  getJobsRecruiters,
  getJobsRefusalReasons,
  getJobsSearch,
  getJobsStages,
  getJobsStagesAutomation,
  getJobsStagesAutomations,
  getJobsStatus,
} from '@/api/jobs'
import { JobFilterValues } from '@/schemas/job'

/*
 ** Query Options
 */
// GET /jobs
export const jobsQuery = (filters: JobFilterValues) =>
  queryOptions({
    queryFn: () => getJobs(filters),
    queryKey: ['jobs', filters],
  })

// GET /jobs/${jobId}/board
export const jobsBoardQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsBoard(jobId),
    queryKey: ['jobs', jobId, 'board'],
  })

// GET /jobs/${jobId}/board/automations
export const jobsBoardAutomationsQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsBoardAutomations(jobId),
    queryKey: ['jobs', jobId, 'board-automations'],
  })

// GET /jobs/${jobId}/info
export const jobsInfoQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsInfo(jobId),
    queryKey: ['jobs', jobId, 'info'],
  })

// GET /jobs/${jobId}/description
export const jobsDescriptionQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsDescription(jobId),
    queryKey: ['jobs', jobId, 'description'],
  })

// GET /jobs/${jobId}/stages
export const jobsStagesQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsStages(jobId),
    queryKey: ['jobs', jobId, 'stages'],
  })

// GET /jobs/${jobId}/recruiters
export function jobsRecruitersQuery(jobId: string) {
  return queryOptions({
    queryFn: () => getJobsRecruiters(jobId),
    queryKey: ['jobs', jobId, 'recruiters'],
  })
}

// GET /jobs/${jobId}/refusal-reasons
export const jobsRefusalReasonsQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsRefusalReasons(jobId),
    queryKey: ['jobs', jobId, 'refusal-reasons'],
  })

// GET /jobs/${jobId}/stages/${stageId}/automations
export const jobsStagesAutomationsQuery = (jobId: string, stageId?: string) =>
  queryOptions({
    enabled: !!stageId,
    queryFn: () => getJobsStagesAutomations(jobId, stageId),
    queryKey: ['jobs', jobId, 'stages', stageId, 'automations'],
  })

// GET /jobs/${jobId}/stages/${stageId}/automations/${automationId}
export const jobsStagesAutomationQuery = <T,>(jobId: string, stageId?: string, automationId?: string) =>
  queryOptions({
    enabled: !!automationId && !!stageId,
    queryFn: () => getJobsStagesAutomation<T>(jobId, stageId, automationId),
    queryKey: ['jobs', jobId, 'stages', stageId, 'automations', automationId],
  })

// GET /jobs/search
export const jobsSearchQuery = (query?: string) =>
  queryOptions({
    enabled: !!query,
    queryFn: () => getJobsSearch(query),
    queryKey: ['jobs', 'search', query],
  })

// GET /jobs/${jobId}/status
export const jobsStatusQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsStatus(jobId),
    queryKey: ['jobs', jobId, 'status'],
  })
