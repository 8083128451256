import { Text } from '@/components/ui/text'
import { CommentContent } from '@/types/application/activities'

export function Comment({ comment }: CommentContent) {
  return (
    <div className="rounded border bg-muted px-2 py-1">
      <Text size="xs">
        <span dangerouslySetInnerHTML={{ __html: comment }}></span>
      </Text>
    </div>
  )
}
