import {
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  FormatDistanceToNowStrictOptions,
  isToday as isTodayDateFns,
  subDays,
  differenceInMilliseconds,
} from 'date-fns'

export function getDistanceToNowStrict(date: string, options?: FormatDistanceToNowStrictOptions) {
  const now = new Date()
  const distanceInSeconds = Math.abs((new Date(date).getTime() - now.getTime()) / 1000)

  if (distanceInSeconds < 60) {
    return 'now'
  }
  return formatDistanceToNowStrict(date, options)
}

export function getFormattedDate(date: string) {
  return format(new Date(date), 'dd/MM/yyyy')
}

export function getFormattedDateTime(date: string | Date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm')
}

export function isAfterToday(date: string) {
  return isAfter(new Date(date), new Date())
}

export function isToday(date: string) {
  return isTodayDateFns(new Date(date))
}

export function isBeforeToday(date: string | Date) {
  return isBefore(date, subDays(Date.now(), 1))
}

export function calculateDateProgress(startDate: Date | null, closeDate: Date | null): number {
  if (!startDate || !closeDate) return 0

  const today = new Date()
  if (isAfter(today, closeDate)) return 100
  if (isBefore(today, startDate)) return 0

  const totalDuration = differenceInMilliseconds(closeDate, startDate)
  const elapsedDuration = differenceInMilliseconds(today, startDate)
  const progress = (elapsedDuration / totalDuration) * 100

  return Math.min(Math.max(Math.round(progress * 100) / 100, 0), 100)
}
