import { put } from '@/services/put'
import { BulkApplicationsBody } from '@/types/job/bulk-applications'

/**
 * URL : /jobs/:jobId/bulk
 */
export function updateJobBulkApplications(jobId: string, body: BulkApplicationsBody) {
  return put<BulkApplicationsBody, { stageTarget: number | null; stageValue: number | null }>(
    `/jobs/${jobId}/bulk`,
    body,
  )
}
