import * as React from 'react'

import * as ProgressPrimitive from '@radix-ui/react-progress'
import { VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

import { indicatorVariants, progressVariants } from './progress.style'

interface ProgressProps
  extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>,
    VariantProps<typeof progressVariants> {}

const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, ProgressProps>(
  ({ className, size, value, variant, ...props }, ref) => (
    <ProgressPrimitive.Root className={cn(progressVariants({ className, size, variant }))} ref={ref} {...props}>
      <ProgressPrimitive.Indicator
        className={indicatorVariants({ variant })}
        style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
      />
    </ProgressPrimitive.Root>
  ),
)
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
