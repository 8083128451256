import { useState } from 'react'

import { useNavigate } from '@tanstack/react-router'

import { useInfiniteNotifications } from '../hooks/useInfiniteNotitications'
import { useReadAllNotifications, useReadNotification, useUnreadNotification } from '../hooks/useNotifications'

type FilterType = 'all' | 'unread' | 'read'

export const useNotificationDrawer = () => {
  const [filter, setFilter] = useState<FilterType>('all')
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({})

  const { mutate: readNotification } = useReadNotification()
  const { mutate: unreadNotification } = useUnreadNotification()
  const { mutate: readAllNotification } = useReadAllNotifications()
  const { data, error, hasNextPage, isFetchingNextPage, loadMoreRef } = useInfiniteNotifications()

  const navigate = useNavigate({ from: '/' })

  const markAllAsRead = () => {
    readAllNotification()
  }

  const read = (id: string) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }))
    readNotification(id, {
      onSettled: () => {
        setLoadingStates((prev) => ({ ...prev, [id]: false }))
      },
    })
  }

  const unread = (id: string) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }))
    unreadNotification(id, {
      onSettled: () => {
        setLoadingStates((prev) => ({ ...prev, [id]: false }))
      },
    })
  }

  const filteredNotifications = data?.items.filter((notification) => {
    switch (filter) {
      case 'unread':
        return !notification.isRead
      case 'read':
        return notification.isRead
      default:
        return true
    }
  })

  const navigateToApplication = async ({
    applicationId,
    isRead,
    jobId,
    notificationId,
  }: {
    isRead: boolean
    notificationId: string
    jobId: string
    applicationId: string
  }) => {
    if (!isRead) {
      readNotification(notificationId)
    }
    await navigate({
      params: { applicationId, jobId },
      search: { view: 'application' },
      to: '/jobs/$jobId/applications/$applicationId',
    })
  }

  const isNotificationLoading = (id: string) => loadingStates[id] || false

  return {
    error,
    filter,
    hasNextPage,
    isFetchingNextPage,
    isNotificationLoading,
    loadMoreRef,
    markAllAsRead: markAllAsRead,
    navigateToApplication,
    notifications: filteredNotifications || [],
    read,
    setFilter,
    unread,
    unreadCount: data?.totalUnreadNotifications || 0,
  }
}
