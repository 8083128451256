import { ApiGateway } from '@/modules/api/api.gateway'
import {
  JobInternalInformationsResponseDto,
  JobSearchResponseDto,
  JobStagesResponseDto,
  UpdateJobInternalInformationsRequestDto,
} from '@/modules/job/domain/entities/job.entity'
import { IJobRepository } from '@/modules/job/domain/repositories/job.repository'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'

export class JobRepository implements IJobRepository {
  constructor(private api: ApiGateway) {}

  async search(query: string): Promise<JobSearchResponseDto[]> {
    return this.api.get<JobSearchResponseDto[]>(`/jobs/search?q=${query}`)
  }

  async getStages(jobId: string): Promise<JobStagesResponseDto> {
    return this.api.get<JobStagesResponseDto>(`/jobs/${jobId}/stages`)
  }

  async getInternalInformations(jobId: string): Promise<JobInternalInformationsResponseDto> {
    return this.api.get<JobInternalInformationsResponseDto>(`/jobs/${jobId}/internal-informations`)
  }

  async updateInternalInformations(
    jobId: string,
    data: UpdateJobInternalInformationsRequestDto,
  ): Promise<IdResponseDto> {
    return this.api.put<IdResponseDto, UpdateJobInternalInformationsRequestDto>(
      `/jobs/${jobId}/internal-informations`,
      data,
    )
  }
}
