import { useEffect } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'

import { ApiGateway } from '@/modules/api/api.gateway'

import { NotificationReposity } from '../../adapter/notification.repository'
import { NotificationUseCase } from '../../application/notification.use-case'

const apiGateway = new ApiGateway()
const notificationRepository = new NotificationReposity(apiGateway)
const notificationUseCase = new NotificationUseCase(notificationRepository)

const useInfiniteNotifications = () => {
  const { inView, ref } = useInView()

  const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage, status } = useInfiniteQuery({
    queryFn: ({ pageParam }) => notificationUseCase.getAll({ page: pageParam }),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    getNextPageParam: (lastPage) =>
      lastPage.pagination.page < lastPage.pagination.totalPages ? lastPage.pagination.page + 1 : undefined,
    initialPageParam: 1,
    queryKey: ['notifications'],
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: false,
    select: (data) => ({
      items: data.pages.flatMap((page) => page.items),
      paginationInfo: data.pages[data.pages.length - 1].pagination,
      totalUnreadNotifications: data.pages[data.pages.length - 1].totalUnreadNotifications,
    }),
  })

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage().catch(() => {})
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage])

  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    loadMoreRef: ref,
    status,
  }
}

export { useInfiniteNotifications }
