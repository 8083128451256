import { cva } from 'class-variance-authority'

export const progressVariants = cva('relative w-full overflow-hidden rounded-full', {
  defaultVariants: {
    size: 'default',
    variant: 'primary',
  },
  variants: {
    size: {
      default: 'h-2',
      sm: 'h-1',
    },
    variant: {
      green: 'bg-green/20',
      primary: 'bg-primary/20',
      red: 'bg-red/20',
      yellow: 'bg-yellow/20',
    },
  },
})

export const indicatorVariants = cva('size-full flex-1 transition-all', {
  defaultVariants: {
    variant: 'primary',
  },
  variants: {
    variant: {
      green: 'bg-green',
      primary: 'bg-primary',
      red: 'bg-red',
      yellow: 'bg-yellow',
    },
  },
})
