import {
  NotiticationRequestDto,
  PaginatedNotificationResponse,
} from '@/modules/notification/domain/entities/notification.entity'
import { INotificationRepository } from '@/modules/notification/domain/repositories/notification.repository'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'
import { PaginationDto } from '@/shared/domain/entities/pagination.entity'

export class NotificationUseCase {
  constructor(private notificationRepository: INotificationRepository) {}

  async getAll(pagination: PaginationDto): Promise<PaginatedNotificationResponse> {
    return this.notificationRepository.getAll(pagination)
  }

  async read(id: string): Promise<IdResponseDto> {
    return this.notificationRepository.read(id)
  }

  async unread(id: string): Promise<IdResponseDto> {
    return this.notificationRepository.unread(id)
  }

  async readAll(): Promise<boolean> {
    return this.notificationRepository.readAll()
  }

  async create(data: NotiticationRequestDto): Promise<IdResponseDto> {
    return this.notificationRepository.create(data)
  }
}
