import { ReactNode } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import {
  BellIcon,
  BellOffIcon,
  CheckIcon,
  ListFilterIcon,
  Loader2,
  MoreVerticalIcon,
  SquareCheckBigIcon,
  SquareIcon,
} from 'lucide-react'

import { Button } from '@/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Text } from '@/components/ui/text'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'

import { useNotificationDrawer } from './useNotificationDrawer'

import { NotificationResponseDto } from '../../domain/entities/notification.entity'

const NotificationDrawer = ({ children }: { children: ReactNode }) => {
  const { filter, hasNextPage, isFetchingNextPage, loadMoreRef, markAllAsRead, notifications, setFilter, unreadCount } =
    useNotificationDrawer()

  return (
    <Sheet>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="flex flex-col gap-0 p-0">
        <SheetHeader className="border-b p-4">
          <div className="flex items-center gap-4">
            <SheetTitle>Notifications</SheetTitle>
            {unreadCount > 0 && (
              <span className="rounded-full bg-destructive px-2 py-1 text-xs text-destructive-foreground">
                {unreadCount} unread{unreadCount > 1 ? 's' : ''}
              </span>
            )}
            <div className="ml-auto flex items-center pr-4">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button size="icon" variant="ghost">
                    <ListFilterIcon size={16} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[200px]">
                  <DropdownMenuItem className="flex items-center gap-2" onClick={() => setFilter('all')}>
                    {filter === 'all' && <CheckIcon size={16} />}
                    <span className={filter === 'all' ? 'font-medium' : ''}>All notifications</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem className="flex items-center gap-2" onClick={() => setFilter('unread')}>
                    {filter === 'unread' && <CheckIcon size={16} />}
                    <span className={filter === 'unread' ? 'font-medium' : ''}>Unread</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem className="flex items-center gap-2" onClick={() => setFilter('read')}>
                    {filter === 'read' && <CheckIcon size={16} />}
                    <span className={filter === 'read' ? 'font-medium' : ''}>Read</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button size="icon" variant="ghost">
                    <MoreVerticalIcon size={16} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem className="flex items-center gap-2" onClick={markAllAsRead}>
                    <BellOffIcon size={16} />
                    Mark all as read
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </SheetHeader>
        <ScrollArea className="flex-1">
          <>
            {notifications && notifications?.length === 0 ? (
              <div className="flex h-24 items-center justify-center">
                <Text size="sm" variant="muted">
                  {filter === 'unread' && 'No unread notifications'}
                  {filter !== 'unread' && 'No notifications'}
                </Text>
              </div>
            ) : (
              <>
                <AnimatePresence>
                  {notifications.map((notification) => (
                    <NotificationItem key={notification.id} notification={notification} />
                  ))}
                </AnimatePresence>
                <div className="flex h-16 items-center justify-center" ref={loadMoreRef}>
                  {isFetchingNextPage ? (
                    <Loader2 className="animate-spin" size={16} />
                  ) : hasNextPage ? (
                    <Text size="sm">Scroll to load more notifications</Text>
                  ) : (
                    <Text size="sm" variant="muted">
                      No more notifications
                    </Text>
                  )}
                </div>
              </>
            )}
          </>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  )
}

const NotificationItem = ({ notification }: { notification: NotificationResponseDto }) => {
  const { isNotificationLoading, navigateToApplication, read, unread } = useNotificationDrawer()
  const isLoading = isNotificationLoading(notification.id)

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={cn(
        'text-left relative cursor-pointer border-b py-2 pl-6 pr-8 bg-muted/50 hover:bg-muted',
        notification.isRead && 'bg-white opacity-50',
        isLoading && 'cursor-wait pointer-events-none',
      )}
      initial={{ opacity: 0 }}
      key={notification.id}
      onClick={async () => {
        await navigateToApplication({
          applicationId: notification.data.applicationId,
          isRead: notification.isRead,
          jobId: notification.data.jobId,
          notificationId: notification.id,
        })
      }}
      role="button"
    >
      {!notification.isRead && <span className="absolute left-2 top-3.5 size-2 rounded-full bg-destructive" />}
      <Text size="sm">
        <span dangerouslySetInnerHTML={{ __html: notification.content }} />
      </Text>
      <div className="absolute right-3 top-2">
        {!notification.isRead && (
          <Tooltip>
            <TooltipTrigger>
              <SquareIcon
                className="text-foreground/20 transition-colors hover:text-foreground"
                onClick={(e) => {
                  e.stopPropagation()
                  read(notification.id)
                }}
                size={16}
              />
            </TooltipTrigger>
            <TooltipContent align="end">Mark as read</TooltipContent>
          </Tooltip>
        )}
        {notification.isRead && (
          <Tooltip>
            <TooltipTrigger>
              <SquareCheckBigIcon
                className="text-foreground/20 transition-colors hover:text-foreground"
                onClick={(e) => {
                  e.stopPropagation()
                  unread(notification.id)
                }}
                size={16}
              />
            </TooltipTrigger>
            <TooltipContent align="end">Mark as unread</TooltipContent>
          </Tooltip>
        )}
      </div>
      {isLoading && (
        <div className="absolute inset-0 flex items-end justify-end p-2 pr-3">
          <Loader2 className="animate-spin text-foreground/20" size={16} />
        </div>
      )}
    </motion.div>
  )
}

const NotificationDrawerTrigger = () => {
  const { unreadCount } = useNotificationDrawer()

  return (
    <NotificationDrawer>
      <Button className="relative rounded-full" size="icon" variant="outline">
        <BellIcon size={16} />
        {unreadCount > 0 && (
          <span className="absolute -right-1 -top-1 flex size-4 items-center justify-center rounded-full bg-destructive text-[10px] text-white">
            {unreadCount}
          </span>
        )}
      </Button>
    </NotificationDrawer>
  )
}

export { NotificationDrawer, NotificationDrawerTrigger }
