import { createFileRoute } from '@tanstack/react-router'

import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'

export const Route = createFileRoute('/_authenticated/jobs_/$jobId_/edit/internal-informations')({
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton title="Internal informations" />
    </>
  ),
})
