import { useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { OrganizationEmailTemplatesSelect } from '@/components/form/selects/OrganizationEmailTemplatesSelect'
import { Tiptap } from '@/components/tiptap/tiptap'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { EMAIL_TEMPLATE_TAGS } from '@/constants/emailTemplateTags'
import { emailTemplateQuery } from '@/queries/use-email-templates-queries'
import { useCreateEmailsToSendCustom } from '@/queries/use-emails-to-send-mutations'
import {
  emailsToSendCustomSchema,
  EmailsToSendCustomValues,
  emailsToSendCustomValidations as validations,
} from '@/schemas/emails-to-send/custom'
import {
  convertMentionHtmlToVariables,
  convertVariablesToMentionHtml,
  removeHTMLTags,
  replaceAtSymbolWithCurlyBraces,
} from '@/utils/string'

interface ContactFormProps {
  onClose: () => void
}

export function ContactForm({ onClose }: ContactFormProps) {
  const { applicationId } = useParams({ from: '/_authenticated/jobs_/$jobId/applications/$applicationId' })
  const form = useForm<EmailsToSendCustomValues>({
    defaultValues: {
      applicationId,
      body: '',
      emailTemplateId: '',
      subject: '',
    },
    resolver: zodResolver(emailsToSendCustomSchema),
  })

  const { control, handleSubmit, setValue, watch } = form
  const emailTemplateId = watch('emailTemplateId')
  const { data: emailTemplate } = useQuery(emailTemplateQuery(emailTemplateId))
  const { mutate: createEmailsToSendCustom } = useCreateEmailsToSendCustom()

  useEffect(() => {
    if (emailTemplate) {
      setValue('subject', convertVariablesToMentionHtml(emailTemplate.subject))
      setValue('body', convertVariablesToMentionHtml(emailTemplate.body))
    }
  }, [emailTemplate, setValue])

  const onSubmit = (values: EmailsToSendCustomValues) => {
    const { applicationId, body, subject } = values

    values.body = replaceAtSymbolWithCurlyBraces(convertMentionHtmlToVariables(body))
    values.subject = removeHTMLTags(convertMentionHtmlToVariables(subject))

    createEmailsToSendCustom(
      {
        body: {
          applicationId,
          body: values.body,
          emailTemplateId: values.emailTemplateId,
          subject: values.subject,
        },
      },
      {
        onSuccess: () => {
          toast.success('Email sent to applicant successfully.')
          onClose()
        },
      },
    )
  }

  return (
    <Form {...form}>
      <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Template"
          name="emailTemplateId"
          renderSkeleton={(field) => <OrganizationEmailTemplatesSelect onChange={field.onChange} value={field.value} />}
          validation={validations.emailTemplateId}
        />
        <CustomFormField
          control={control}
          description="Type @ to display a list of variables and select them to insert into the text."
          descriptionTooltipContent={EMAIL_TEMPLATE_TAGS.map(({ label }) => label).join('\n')}
          fieldType={FormFieldType.SKELETON}
          label="Subject"
          name="subject"
          placeholder="Interview Invitation"
          renderSkeleton={(field) => (
            <Tiptap
              extensions="input"
              name={field.name}
              onChange={field.onChange}
              suggestionItems={EMAIL_TEMPLATE_TAGS}
              value={field.value}
            />
          )}
          validation={validations.subject}
        />
        <CustomFormField
          control={control}
          description="Type @ to display a list of variables (applicant lastname, job title, etc...) and select them to insert into the text."
          descriptionTooltipContent={EMAIL_TEMPLATE_TAGS.map(({ label }) => label).join('\n')}
          fieldType={FormFieldType.SKELETON}
          label="Body"
          name="body"
          renderSkeleton={(field) => (
            <Tiptap
              extensions="emailTemplate"
              name={field.name}
              onChange={field.onChange}
              suggestionItems={EMAIL_TEMPLATE_TAGS}
              value={field.value}
            />
          )}
          validation={validations.body}
        />
        <Button className="self-center" type="submit">
          Send email
        </Button>
      </form>
    </Form>
  )
}
