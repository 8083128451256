import { useState } from 'react'

import { useParams } from '@tanstack/react-router'
import { ArrowRightLeft } from 'lucide-react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Progress } from '@/components/ui/progress'
import { Text } from '@/components/ui/text'
import { useBulkActions } from '@/hooks/use-bulk-actions'
import { useUpdateJobBulkApplications } from '@/queries/use-jobs-mutations'
import { getApplicationMatchingColor } from '@/utils/color'

import { StageSelect } from '../stage-select'

export function MoveToStageAction() {
  const [open, setOpen] = useState(false)
  const { jobId } = useParams({ from: '/_authenticated/jobs_/$jobId' })
  const { clearSelected, selected, toggleOpen } = useBulkActions()
  const hasSelected = selected.length > 0

  const { mutate: updateJobBulkApplications } = useUpdateJobBulkApplications(jobId)

  const moveToStage = (value: string) => {
    updateJobBulkApplications(
      {
        action: 'MOVE_TO_STAGE',
        applicationIds: selected,
        value: {
          stageId: value,
        },
      },
      {
        onSuccess: ({ stageTarget, stageValue }) => {
          const title = `${selected.length > 1 ? 'Applicants' : 'Applicant'} moved to stage successfully.`

          if (stageTarget && stageValue) {
            const percent = Math.round((stageValue / stageTarget) * 100)

            toast(title, {
              description: (
                <div className="w-full space-y-1">
                  <Progress value={percent} variant={getApplicationMatchingColor(percent)} />
                  <Text size="xs">
                    You reach {stageValue}/{stageTarget} of your objective.
                  </Text>
                </div>
              ),
            })
          } else {
            toast.success(title)
          }

          clearSelected()
          setOpen(false)
          toggleOpen()
        },
      },
    )
  }

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>
        <Button disabled={!hasSelected} onClick={() => setOpen(true)}>
          <ArrowRightLeft size={16} />
          Move to stage
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <StageSelect
          onChange={(data) => {
            if (data?.value) moveToStage(data.value)
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
