export const EMAIL_TEMPLATE_TAGS = [
  { id: 'APPLICANT_FIRSTNAME', label: 'APPLICANT_FIRSTNAME' },
  { id: 'APPLICANT_LASTNAME', label: 'APPLICANT_LASTNAME' },
  { id: 'APPLICATION_ID', label: 'APPLICATION_ID' },
  { id: 'ATS_LINK', label: 'ATS_LINK' },
  { id: 'CALENDLY_LINK', label: 'CALENDLY_LINK' },
  { id: 'INTERVIEW_DATE', label: 'INTERVIEW_DATE' },
  { id: 'INTERVIEW_LOCATION', label: 'INTERVIEW_LOCATION' },
  { id: 'INTERVIEW_TIME', label: 'INTERVIEW_TIME' },
  { id: 'INTERVIEW_TIMEZONE', label: 'INTERVIEW_TIMEZONE' },
  { id: 'INTERVIEW_TYPE', label: 'INTERVIEW_TYPE' },
  { id: 'JOB_ID', label: 'JOB_ID' },
  { id: 'JOB_PUBLIC_URL', label: 'JOB_PUBLIC_URL' },
  { id: 'JOB_TITLE', label: 'JOB_TITLE' },
  { id: 'LEAD_RECRUITER_EMAIL', label: 'LEAD_RECRUITER_EMAIL' },
  { id: 'LEAD_RECRUITER_FIRSTNAME', label: 'LEAD_RECRUITER_FIRSTNAME' },
  { id: 'LEAD_RECRUITER_LASTNAME', label: 'LEAD_RECRUITER_LASTNAME' },
  { id: 'ORGANIZATION_NAME', label: 'ORGANIZATION_NAME' },
  { id: 'RECRUITER_EMAIL', label: 'RECRUITER_EMAIL' },
  { id: 'RECRUITER_FIRSTNAME', label: 'RECRUITER_FIRSTNAME' },
  { id: 'RECRUITER_LASTNAME', label: 'RECRUITER_LASTNAME' },
  { id: 'RECRUITERS', label: 'RECRUITERS' },
]
