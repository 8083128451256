import { z } from 'zod'

import { JobPriority } from '@/modules/job/domain/enums/job-priority.enum'

export const objectiveFiltersSchema = z.object({
  groupBy: z.enum(['priority', 'recruiter']).optional(),
  limit: z.number().catch(10).optional(),
  page: z.number().catch(1).optional(),
  priorities: z.nativeEnum(JobPriority).array().optional(),
  recruiters: z.string().array().optional(),
  sort: z.enum(['CLOSING_ASC', 'CLOSING_DESC', 'PUBLISHED_ASC', 'PUBLISHED_DESC']).optional(),
  statuses: z.string().array().optional(),
})

export type ObjectiveFilterQueryParamsDto = z.infer<typeof objectiveFiltersSchema>
