import { RecruiterMinimalResponseDto } from '@/modules/recruiter/domain/entities/recruiter.entity'
import { IRecruiterRepository } from '@/modules/recruiter/domain/repositories/recruiter.repository'

export class RecruiterUseCase {
  constructor(private recruiterRepository: IRecruiterRepository) {}

  async getAllMinimal(): Promise<RecruiterMinimalResponseDto[]> {
    return this.recruiterRepository.getAllMinimal()
  }
}
