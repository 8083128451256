import { queryOptions, useQuery } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { RecruiterRepository } from '@/modules/recruiter/adapter/recruiter.repository'
import { RecruiterUseCase } from '@/modules/recruiter/application/recruiter.use-case'
import { RecruiterMinimalResponseDto } from '@/modules/recruiter/domain/entities/recruiter.entity'

const apiGateway = new ApiGateway()
const recruiterRepository = new RecruiterRepository(apiGateway)
const recruiterUseCase = new RecruiterUseCase(recruiterRepository)

const recruitersMinimalQueryOptions = queryOptions({
  queryFn: () => recruiterUseCase.getAllMinimal(),
  queryKey: ['recruiters', 'minimal'],
})

const useRecruitersMinimal = <T,>({ select }: { select?: (data: RecruiterMinimalResponseDto[]) => T } = {}) => {
  return useQuery({ ...recruitersMinimalQueryOptions, select })
}

export { useRecruitersMinimal }
