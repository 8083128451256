import { queryOptions, useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { UserRepository } from '@/modules/user/adapter/user.repository'
import { UserUseCase } from '@/modules/user/application/user.use-case'
import { CheckEmailDto } from '@/modules/user/domain/schemas/check-email'
import { CreateUserApplicationDto } from '@/modules/user/domain/schemas/create-user-application.schema.ts'
import { UpdatePersonalDataValues } from '@/modules/user/domain/schemas/update-personal-data.schema'

const apiGateway = new ApiGateway()
const userRepository = new UserRepository(apiGateway)
const userUseCase = new UserUseCase(userRepository)

const searchUsersQueryOptions = (query: string) =>
  queryOptions({
    enabled: !!query,
    queryFn: () => userUseCase.search(query),
    queryKey: ['users/search', query],
  })

const useSearchUsers = (query: string) => {
  return useQuery(searchUsersQueryOptions(query))
}

const userQueryOptions = (id: string) =>
  queryOptions({
    queryFn: () => userUseCase.get(id),
    queryKey: ['user', id],
  })

const useUser = (id: string) => {
  return useQuery(userQueryOptions(id))
}

const useSuspenseUser = (id: string) => {
  return useSuspenseQuery(userQueryOptions(id))
}

const userPersonalDataQueryOptions = (id: string) =>
  queryOptions({
    queryFn: () => userUseCase.getPersonalData(id),
    queryKey: ['user/personal-data', id],
  })

const useUserPersonalData = (id: string) => {
  return useQuery(userPersonalDataQueryOptions(id))
}

const useUpdatePersonalData = (id: string) => {
  return useMutation({
    mutationFn: (data: UpdatePersonalDataValues) => userUseCase.updatePersonalData(id, data),
  })
}

const useCheckEmail = () => {
  return useMutation({
    mutationFn: (data: CheckEmailDto) => userUseCase.checkEmail(data),
  })
}

const useCreateUserApplication = () => {
  return useMutation({
    mutationFn: (data: CreateUserApplicationDto) => userUseCase.createApplication(data),
  })
}

const useUploadResume = () => {
  return useMutation({
    mutationFn: (data: FormData) => userUseCase.uploadResume(data),
  })
}

export { userQueryOptions, userPersonalDataQueryOptions }
export {
  useSearchUsers,
  useUser,
  useSuspenseUser,
  useUserPersonalData,
  useUpdatePersonalData,
  useCheckEmail,
  useCreateUserApplication,
  useUploadResume,
}
